<template>
  <div>
    <h1>Galeri</h1>
    <Gallery></Gallery>
  </div>
</template>

<script>
import Gallery from './gallery/Gallery.vue';

export default {
  components: {
    Gallery
  }
};
</script>

<style>
</style>