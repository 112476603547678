<template>
    <div class="song">
        <div class="container">
            <h1>ŞARKILAR</h1>
            <div class="songs">
                <div class="songs-name">
                    <ul>
                        <li v-for="(sound, index) in videoData" :key="index" @click="openSound(index)">
                           <a> {{ sound.title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="songs-player">
                    <div class="songs-player-movies" v-if="selectedSongs">
                        <h4 class="songs-player-title">{{ selectedSongs.title }}</h4>
                        <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="'https://www.youtube.com/embed/'+this.selectedSongs.url" controls></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

  export default {
    data() {
      return {
        videoData: [
          {
            title: 'Şemmamme',
            url: 'E_zUxFrJ_SE'
          },
          {
            title: 'Sarhoş',
            url: 'C9gNvCcAIeg'
          },
          {
            title: 'Sevda Ne Yana Düşer',
            url: 'RIqsdDYhJac'
          },
          {
            title: 'Ayağında Kundura',
            url: '1YXOH8OokKA'
          },
          {
            title: 'Gülüm Benim',
            url: 'AogNQspHRb4'
          },
          {
            title: 'Allah Allah',
            url: 'jxPtEr0tHgI'
          },
          {
            title: 'Yalan',
            url: 'SFwMtAllm7o'
          },
          {
            title: 'Aramam',
            url: 'VNZuTrvXfPE'
          },
          {
            title: 'Mavi Mavi',
            url: 'nWIIS7vgT-E'
          },
          {
            title: 'Kal Benim İçin',
            url: 'NntT0-dxI4Q'
          },
          {
            title: 'Gülümse Kaderine',
            url: '5yCx_YR791Q'
          },
          {
            title: 'Mavişim',
            url: 'WA2hqcSm7gU'
          },
          {
            title: 'Mutlu Ol Yeter',
            url: '6LM_mYcwTpM'
          },
          {
            title: 'Vur Gitsin Beni',
            url: 'vjvcxwjI60I'
          },
          {
            title: 'Sabuha',
            url: 'dJRzBGDxvv4'
          },
          {
            title: 'Yalanmış',
            url: '3gAdf8CU6xk'
          },
          {
            title: 'Ne Faydası Var',
            url: 'xSbRuCkODDA'
          },
          {
            title: 'Yetiş Sevgilim',
            url: 'nWdhKbCzQuo'
          },
          {
            title: 'Yıkılmışım Ben',
            url: '0YibeHhfy8Y'
          },
          {
            title: 'Hani Gelecektin',
            url: 'FxA1SwLpVAg'
          },
          {
            title: 'Selam Olsun',
            url: 'x7uz4_R4LYs'
          },
          {
            title: 'Kara Zindan',
            url: 'Qhjqep_Ecc8'
          },
          {
            title: 'Dertler Benim Olsun',
            url: 'zu0yoW6HVf4'
          },
          {
            title: 'Tövbeler',
            url: 'yxCKRHbppSo'
          },
          {
            title: 'Yalnızım',
            url: 'pd2R40Ty2ZI'
          },
          {
            title: 'Özledim Seni',
            url: 'aajAVEGpkGQ'
          },
          {
            title: 'Gönül Dağı',
            url: 'fA_takJOGtw'
          },
          {
            title: 'Acı Gerçekler',
            url: 'MuKFppcc28g'
          },
          {
            title: 'Kop Gel Günahlarından',
            url: 'FJPBLpwNIeI'
          },
          {
            title: 'Ne Sevdalar Yaşadım',
            url: 'mW2kc2kQ6oo'
          },
          // diğer videolar
        ],
        selectedSongs: {
        url : "E_zUxFrJ_SE",
        title: 'Şemmamme ',
      }
        
      };
    },
    methods:{
        openSound(index) {
            this.selectedSongs = this.videoData[index];
            console.log(this.selectedSongs.url)
        }
    }
  };


  </script>
  
  <style scoped>
 

  </style>