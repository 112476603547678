<template>
<div class="container">
  <h1>İBO SHOW</h1>
  <div class="photo-gallery">
    <div target="_blank" @click="eventUrL(photo)" v-for="(photo, index) in photos" :key="index" class="photo">
      <img class="show-img" :src="photo.imagePath" >
      <p >{{ photo.description }}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      photos: [
        { imagePath: require('@/assets/images/show/yılbası2023.jpg'), description: 'İbo Show 2023 Yılbaşı | Özel Videoları' , url:"https://www.youtube.com/watch?v=TaELhvokKuM&list=PL-OssQ7RtGi8Q9dlKNDfhXDV8X9oJh90N" },
        { imagePath: require('@/assets/images/show/iboshow.jpg'), description: 'Popüler videolar'  , url : "https://www.youtube.com/watch?v=9raa8rVZptk&list=UULPz1sDPtUPQ7BytUJ4fttcKg" },
        { imagePath: require('@/assets/images/show/iboshow2022-1.jpg'), description: 'İbo Show 2022 Sezonu Tüm Bölümleri' , url : "https://www.youtube.com/watch?v=j0yyjDHFZ3s&list=PL-OssQ7RtGi9m8SL6s78LgdlKDjg9orWL" },
        { imagePath: require('@/assets/images/show/iboshow2022-1.jpg'), description: 'İbo Show 2022 Sezonu Anlar' , url : "https://www.youtube.com/watch?v=hsmp6uhhyPI&list=PL-OssQ7RtGi_LWG3wNfApQMVCOUZCdDI6" },
        { imagePath: require('@/assets/images/show/iboshow2022-1.jpg'), description: 'İbo Show 2020-2021-2022 Sezonları Popüler Bölümler' , url : "https://www.youtube.com/watch?v=VYwvr3gLdbU&list=PL-OssQ7RtGi8bEPzS8aGNWDSgGV7iM366" },
        { imagePath: require('@/assets/images/show/iboshow2022-1.jpg'), description: 'İbo Show - En İyi Performanslar' , url : "https://www.youtube.com/watch?v=ALYtaHUXsSU&list=PL-OssQ7RtGi8MD_X6koxY3Fb1po5I5Toj" },
        { imagePath: require('@/assets/images/show/iboshow2022-1.jpg'), description: 'İbo Show - Altın Düetler'  , url : "https://www.youtube.com/watch?v=pkIlP54PsX8&list=PL-OssQ7RtGi8bAJ5U6h18sWdEsOL0BuT_"},

      ]
    };
  },

  methods:{
    eventUrL(photo){
      window.open(photo.url, '_blank');
    }
  }
}
</script>

<style >

</style>