<template>
  <div class="inc">
  <el-container>
    <div class="logo">
      <img class="logo-image" src="./assets/images/bodylogo.png" alt="">
    </div>
  <Header></Header>
    <router-view></router-view>
    
  </el-container>
  <Footer></Footer>
  </div>
</template>

<style>
@media (max-width:650px){
  .logo-image{
    height: auto;
    width: 95%;
  }  
}
.dark .disqus-footer__logo .disqus-footer__link {
 
    display: none !important;
}
.disqus-footer__logo .disqus-footer__link{
  display: none !important;
}


 </style>


<script>

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';


export default{
  components:{
        Header,
        Footer,
    }

}

</script>


