import Vue from "vue"
import VueRouter from "vue-router";

import Home from "../components/contents/Home.vue"
import Biyografi from "../components/contents/Biyografi.vue"
import Galeri from "../components/contents/Galeri.vue"
import Show from "../components/contents/Show.vue"
import Sarkilar from "../components/contents/Sarkilar.vue"
import Filmler from "../components/contents/Filmler.vue"


Vue.use(VueRouter)

export const routes =[
    {path:"/" , name:"Home" , component: Home },
    {path:"/Biyografi" , name:"Biyografi" , component: Biyografi },
    {path:"/Galeri" , name:"Galeri" , component: Galeri },
    {path:"/ibo-show" , name:"Show" , component: Show },
    {path:"/Sarkilar" , name:"Sarkilar" , component: Sarkilar },
    {path:"/Filmler" , name:"Filmler" , component: Filmler },
    {path :"*" , redict:"/" , component : Home }
];


export const router = new VueRouter({
    mode: "history",
    routes,
  });


