import axios from "axios";


const instance = axios.create({
	baseURL : "http://localhost:8080/",
    headers: { Authorization: `Bearer BQCWWiiXDSapHYhhF0zdUa02BwwpnQmK4brZV3bAi97FUDsP_1wUxtHKWZDyiqwyCQk-mK86k9kO3eca6EFpYSiwT7XJMYa_t4sfvRldghTyUlcWTA1Ymv57vE5MGzqdL2Z6gCIB-dJEE4mSf9b1HsP2gcqkAfSPoT2AaIxE6fFVF0U2CwBoPcEEBQqIbsw7KPS-0OUd` }
});

//instance.defaults.headers.common['Authorization'] =  https://api.spotify.com/v1/artists/0gPgE6wLLiPnrakh9WcsdQ/albums

export default instance;
