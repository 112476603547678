<template>
<div class="filmler">
    <h1>FİLMLER</h1>
    <div class="container">
        <div class="movie">
            <div class="movie-name">
                <ul>
                    <li v-for="(video, index) in videos" :key="index" @click="openVideo(index)">
                    {{ video.title }}
                    </li>
                </ul>
            </div>

            <div class="movies" v-if="selectedVideo">
                <h4 class="movies-title">{{ selectedVideo.title }}</h4>
                <iframe  allowfullscreen  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" class="iframe" :src="this.selectedVideo.videoUrl" controls></iframe>
            </div>
        </div>
        <div class="description">
            <p>{{ selectedVideo.description }}</p>
        </div>
    </div>
</div>
  </template>


</template>

<script>
export default {
  data() {
    return {
      videos: [
        {
          title: 'Sabuha ',
          description: "Sabuha; İbrahim Tatlıses , Güngör Bayrak, Semra Alper ve Erol Taş'ın oynadığı 1978 yıl yapımı Türk Filmidir.",
          videoUrl:'https://www.youtube.com/embed/mMP-tCSk-Oo'
        },
        {
          title: 'Ayağında Kundura-Ceylan',
          description: "1978 çıkışlı Türk filmidir. İbrahim Tatlıses'in rol aldığı ilk sinema filmi olup yönetmenliğini Oksal Pekmezoğlu üstlenmiştir.",
          videoUrl: 'https://www.youtube.com/embed/tzwlT8LmA88'
        },
        {
          title: 'Kara Yazma',
          description: 'Oyuncular : Perihan Savaş ,  İbrahim Tatlıses ,  Hüseyin Peyda ,  Handan Adalı ,  Diler Saraç',
          videoUrl: 'https://www.youtube.com/embed/vF0OAxRfvXw'
        },
        {
          title: 'Kara Çadırın Kızı: ZEYNEP',
          description:  "Zeynep ; İbrahim Tatlıses ve Perihan Savaş'ın oynadığı senaryosunu Sefa Önal'ın yazdığı 1979 yıl yapımı Türk Filmidir.",
          videoUrl: 'https://www.youtube.com/embed/KYjtGV6uamE'
        },
        {
          title: 'Fadile',
          description:  "Fadile; İbrahim Tatlıses ve Necla Nazır'ın oynadığı Oksal Pekmezoğlu'nun yönetmenliğini üstlendiği Türk filmidir.",
          videoUrl: 'https://www.youtube.com/embed/O8JCavPwQ3g'
        },
        {
          title: 'Ayrılık Kolay Değil',
          description:  "Büyük bir çiftlikte çalışan yoksul bir köylü, çiftlik sahibi Ağa’nın kızına aşıktır. Genç kız da onu sever ama sınıf farklılığı biraraya gelmelerine engel olur. Göç ettiği kentte ünlü bir türkücü olunca büyük bir servete kavuşan genç adamın tek amacı sevdiği kıza kavuşmaktır.",
          videoUrl: 'https://www.youtube.com/embed/m5ukwGF1gPY'
        },
        {
          title: 'Çile',
          description:  " Taksi şöförlüğü yaparak ailesini geçindiren İbo, kardeşinin tıp eğitimi için gereken payı sağlamak ve sevgilisi Bahar'la evlenebilmek için gece gündüz çalışır. Sevdikleri için hiçbir özveriden kaçınmayan İbo, evleneceği gün kardeşinin işlediği bir suçu üstlenerek hapse girer. ",
          videoUrl: 'https://www.youtube.com/embed/SVQnfjuhYpM'
        },
        {
          title: 'Seni Yakacaklar',
          description:  "Aynı kızı seven, kan davalı iki amca çocuğunun mücadele öyküsü.",
          videoUrl: 'https://www.youtube.com/embed/-kCIb_9z3VU'
        },
        {
          title: 'Yaşamak Bu Değil ',
          description:  "Köyünden bir kasabaya inşaat işçisi olarak gelen (İbrahim Tatlıses), vücudunu parayla satan Gülbahar'ın (Suzan Avcı) kızı Emine'yle (Serpil Çakmaklı) evlenir. İnşaat sahibinin oğlu, arkadaşları ile birlikte Emine'yi kaçırırlar. İnşaatçının oğlu Emine'ye, arkadaşları da annesine tecavüz ederler. Emine kendini asar. İbrahim de sonunda karısının intikamını alır.",
          videoUrl: 'https://www.youtube.com/embed/KYjtGV6uamE'
        },
        {
          title: 'Tövbe',
          description:  "Tövbe ; İbrahim Tatlıses ve Oya Aydoğan filminin oynadığı ve Orhan Aksoy'un yönettiği 1981 yıl yapımı Türk filmidir.",
          videoUrl: 'https://www.youtube.com/embed/wztE91vTS0I'
        },
        {
          title: 'Yalan',
          description:  "Film, bir yalan yüzünden birbirine kavuşamayan iki aşığın öyküsünü konu alır.",
          videoUrl: 'https://www.youtube.com/embed/o-T86xLRwBM'
        },
        {
          title: 'Alişan',
          description:  "Alişan; İbrahim Tatlıses, Yaprak Özdemiroğlu'nun oynadığı 1982 yıl yapımı Türk filmidir.",
          videoUrl: 'https://www.youtube.com/embed/XTIDuOOlcI8'
        },
        {
          title: 'Nasıl İsyan Etmem',
          description:  "Babasının parasıyla yaşayan ve serserilik yaparak günlerini geçiren şımarık bir zengin çocuğu kavgaya karışır ve birisini öldürür. İşlediği bu cinayeti de apartmanında kapıcılık yapan saf ve namuslu insanların üstüne yıkar.",
          videoUrl: 'https://www.youtube.com/embed/hMYjPZDSRJs'
        },
        {
          title: 'Yorgun',
          description:  "Film, plak, konser çalışmaları onu yorgun bir adam yapmıştır. Bir gün sokakta rastlayıp yardım ettiği bir çocuk hayatını değiştirir. Küçük dostu ona unuttuğu yaşama sevincini, sadeliği ve küçük mutlulukları hatırlatır. Bu sırada İbrahim ünlü olma hayaliyle kapı kapı dolaşan Seda'nın elinden tutar.",
          videoUrl: 'https://www.youtube.com/embed/g9mS0HwHdJA'
        },
        {
          title: 'Günah',
          description:  "Uzun yol şoförü Yaşar ile kan davasından kaçıp pavyona düşen bir kızın dramatik öyküsü",
          videoUrl: 'https://www.youtube.com/embed/z3yPC__GstI'
        },
        {
          title: 'Futboliye',
          description:  "Futboliye; Aydemir Akbaş ,Bahar Öztan'ın ve İbrahim Tatlıses'in (konuk) oynadığı 1983 yıl yapımı Türk Filmidir.",
          videoUrl: 'https://www.youtube.com/embed/MNhQo1MxTX4'
        },
        {
          title: 'Sevdalandım',
          description:  "Film, gözleri görmeyen genç bir kızla inşaat işçisi bir grup arkadaşın öyküsünü konu alır.",
          videoUrl: 'https://www.youtube.com/embed/ezhgPE172V4'
        },
        {
          title: 'Mavi Mavi',
          description:  "Kerim (İbrahim Tatlıses), okul servisi yapan yoksul bir minibüs şöförüdür. Bir gün bir tartışma sonucu şımarık bir zengin kız olan Sibel (Hülya Avşar) ile karşılaşır. İlk bir-iki karşılaşmada yıldızları barışmamasına hatta bir ara Sibel'in Kerim'den intikam almak istemesine rağmen, Kerim kıza aşık olur. Olaylar, iki sevgilinin kavuşmasına kadar sürer.",
          videoUrl: 'https://www.youtube.com/embed/27qLqs1TbeU'
        },
        {
          title: 'Sevmek',
          description:  " Vücudunu paralı erkeklere kiralayan bir mankenle gece kulübünde tanıştığı Urfalı bir işadamının öyküsü",
          videoUrl: 'https://www.youtube.com/embed/Jn4DDpf9UVI'
        },
        {
          title: 'Yalnızım',
          description:  "Hamile olan bir genç kızla, onu deli gibi seven bir marangozun aşk öyküsü.",
          videoUrl: 'https://www.youtube.com/embed/uri_cSLOHWY'
        },
        {
          title: 'Yıkılmışım Ben',
          description:  "Zengin bir ailenin damadı ile ondan çocuğu olan hasta bir kızın öyküsü.",
          videoUrl: 'https://www.youtube.com/embed/M-VhN8_UMeQ'
        },
        {
          title: 'Allah Allah',
          description:  "Allah Allah; yönetmenliği İbrahim Tatlıses tarafından yapılan, İbrahim Tatlıses , Melike Zobu ve Neslihan Acar'ın oynadığı, 1987 yıl yapımı Türk filmidir.Filmde, iki kız kardeşin aynı adama aşık olması konu edilir.",
          videoUrl: 'https://www.youtube.com/embed/vPUsiH41fJU'
        },
        {
          title: 'Aşıksın',
          description:  "Filmde, şarkıcı İbrahim Tatlıses ile hayranı Deniz’in aşk hikayesi anlatılır",
          videoUrl: 'https://www.youtube.com/embed/b0iu6gsP5MY'
        },
        {
          title: 'Hülya',
          description:  "Hülya Avşar'a Aşık Olan İbrahim Tatlıses gizli gizli onu takip etmeye başlar ve izlemeye karar verir. Ressam olan Hülya Avşar Güzel Resimler çizmektedir.",
          videoUrl: 'https://www.youtube.com/embed/Ab9jlOG8IR4'
        },
        {
          title: 'Bir Kulum İşte',
          description:  "Filmde cezaevinden çıkan ve eski günlerine sünger çeken eski bir kabadayının hikayesi konu ediniliyor.",
          videoUrl: 'https://www.youtube.com/embed/7Ats1ce4zh0'
        },
        {
          title: 'Kara Zindan',
          description:  "Başlık parası ve kadın istismarı gibi konulara değinen film, bir dönem özellikle köylerde yaşanan ve birçok gencin hayatına mal olan başlık parası konusunu işlemiştir.",
          videoUrl: 'https://www.youtube.com/embed/JWIsV3VTJ2g'
        },
        {
          title: 'Ben İnsan Değil Miyim ',
          description:  " Konser vermek için Yunanistan'a giden ünlü bir türkücü. orada Yunanlı bir gazeteci kızla tanuşır. Çok geçmeden aralarında büyük bir aşk başlar. Ancak bu aşk farklı milletlerden ve kültürden gelen bu iki genci bir çok sorunla karşı karşıya getirir.",
          videoUrl: 'https://www.youtube.com/embed/eEIUVfIKwNI'
        },
        {
          title: 'Fosforlu',
          description:  "Çocukluklarından beri ayrılmaz iki arkadaş olan biri kız biri erkek iki yankesicinin başlarından geçen olayları konu alan eğlenceli bir macera filmi.",
          videoUrl: 'https://www.youtube.com/embed/e2Cv-XS_2hY'
        },
        {
          title: 'Aşık Oldum',
          description:  "Açıklama Yok",
          videoUrl: 'https://www.youtube.com/embed/ok075d5wriA'
        },
        {
          title: 'Tetikçi Kemal',
          description:  "Açıklama Yok",
          videoUrl: 'https://www.youtube.com/embed/1WtpeN1OFlY?list=PLmODVUELuWZmHtRk-vm3PuOHfnDW0ptez'
        },
        {
          title: 'Fırat',
          description:  "Açıklama Yok",
          videoUrl: 'https://www.youtube.com/embed/bvl6zWew5c8'
        },
       
      ],
      selectedVideo: {
        videoUrl : "https://www.youtube.com/embed/DeTD0O_CAtU",
        title: 'Sabuha ',
        description: "Sabuha; ibrahim Tatlıses , Güngör Bayrak, Semra Alper ve Erol Taş'ın oynadığı 1978 yıl yapımı Türk Filmidir.",
      }
    };
  },
  methods: {
    openVideo(index) {
      this.selectedVideo = this.videos[index];
      console.log(this.selectedVideo.videoUrl)
    }
  },

};
</script>

<style>

</style>