<template>
    <div class="gallery">
      <el-row>
        <el-col v-for="image in images" :key="image.id" :span="8">
          <img :src="image.imagePath" class="gallery-image" @click="openImage(image)">
        </el-col>
      </el-row>
      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="true">
        <img :src="selectedImage.imagePath" class="dialog-image">
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        images: [
          { id: 1, imagePath: require('@/assets/images/galery/1.jpg') },
          { id: 2,  imagePath: require('@/assets/images/galery/2.jpg') },
          { id: 3,  imagePath: require('@/assets/images/galery/3.jpg') },
          { id: 4,  imagePath: require('@/assets/images/galery/4.jpg') },
          { id: 5,  imagePath: require('@/assets/images/galery/5.jpg') },
          { id: 6,  imagePath: require('@/assets/images/galery/6.jpg') },
          { id: 7,  imagePath: require('@/assets/images/galery/7.jpg') },
          { id: 8,  imagePath: require('@/assets/images/galery/8.jpg') },
          { id: 9,  imagePath: require('@/assets/images/galery/9.jpg') },
          // Diğer resimler...
        ],
        dialogVisible: false,
        selectedImage: ""
      };
    },
    mounted() {
    console.log(this.images);
  },
    methods: {
      openImage(image) {
        this.selectedImage = image;
        this.dialogVisible = true;
      }
    }
  };
  </script>
  
  <style scoped>
  .gallery {
    position: relative;
    width: 80%;
    left: 10% !important;

  }
  
  .gallery-image {
    border: 4px solid #E2AD56;
    border-radius: 5px;
    width: 98%;
    height: 425px;
    cursor: pointer;
  }
  .el-dialog {
    background: #0000009e !important;
    display: none !important;
}
  .dialog-image {
    max-width: 100%;
    max-height: 100%;
  }



  </style>