
import App from './App.vue'
import {router} from './router/index'
import Vuex from 'vuex'
import store from './store/index'
import Vue from 'vue';

import VueDisqus from 'vue-disqus'

Vue.use(VueDisqus, {
  shortname: 'your-shortname-disqus'
})



import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)



import locale from 'element-ui/lib/locale/lang/en'
import Notifications from 'vue-notification';
import CKEditor from 'ckeditor4-vue';
import VueMeta from 'vue-meta'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import './assets/scss/app.scss';


Vue.use(Vuex)
Vue.use(ElementUI, { locale })
Vue.use( CKEditor );

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});




Vue.use(Notifications);

const moment = require('moment')
require('moment/locale/tr')
Vue.use(require('vue-moment'), {moment})

window._ = require("lodash");
Vue.prototype._ = window._;



Vue.prototype.$user = function() {
  return store.getters['auth/user'];
}
Vue.prototype.$user_detail = function() {
    return store.getters['auth/user_detail'];
}
Vue.prototype.$auth = function() {
  return store.getters['auth/isLoggedIn'];
}
Vue.prototype.$generateFilter = function(filter) {
  var query ='?';

  _.forEach(filter, function(value, key) {
    if(value != null || !_.isEmpty(value)){
      query += `filter[${key}]=${value}&`
    }
  });
  return query
}

// MOBILE DETECT
import { isMobile } from 'mobile-device-detect';
Vue.prototype.isMobile = isMobile

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
