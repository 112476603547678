<template>
    <div class="home">
        <div class="card-list1">
            <b-card
            :img-src="biyo"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2">
            <b-card-text>
                İbrahim Tatlıses,in hatayatı için tıklayınız.
            </b-card-text>
            <b-button @click="about" href="#" variant="warning">Biyografi</b-button>
        </b-card>
        <b-card
            :img-src="galery"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2">
            <b-card-text>
                İbrahim Tatlıses fotoğraf galerisine gitmek için tıklayınız.
            </b-card-text>
            <b-button @click="galerys" href="#" variant="warning">Galeri</b-button>
        </b-card>
        <b-card
            :img-src="videos"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2">
            <b-card-text>
                İbrahim Tatlıses filmlerine gitmek için tıklayınız.
            </b-card-text>
            <b-button @click="video"  href="#" variant="warning">Filmler</b-button>
        </b-card>
        <b-card
            :img-src="sound"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2">
            <b-card-text>
                İbrahim Tatlıses şarkılarına gitmek için tıklayınız.
            </b-card-text>
            <b-button @click="sounds" href="#" variant="warning">Şarkılar</b-button>
        </b-card>
        <b-card
            :img-src="show"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2">
            <b-card-text>
                İbo Show izlemek için tıklayınız.
            </b-card-text>
            <b-button @click="shows" href="#" variant="warning">İbo Show</b-button>
        </b-card>
        </div>
       
        <Disqus></Disqus>
</div>
</template>
<script>
    import Disqus from './Disqus.vue'
export default{

    contents:{
        Disqus,
    },
    data(){
        return{
            biyo : require('@/assets/images/galery/homeb.jpeg'),
            galery : require('@/assets/images/galery/homeimage.jpg'),
            videos : require('@/assets/images/galery/homev.jpg'),
            sound : require('@/assets/images/galery/contents-img.jpg'),
            show : require('@/assets/images/galery/homeshow.jpg'),
        }
    },
    methods:{
        about() {
            this.$router.push('/Biyografi')
        },
        galerys() {
            this.$router.push('/Galeri')
        },
        video() {
            this.$router.push('/Filmler')
        },
        sounds() {
            this.$router.push('/Sarkilar')
        },
        shows() {
            this.$router.push('/ibo-show')
        }
    }
}
</script>
<style>

.card{
    background-color: white !important;
}
.card-list1{
    display: flex ;
    flex-direction: row ;
    justify-content: center ;
    align-items: stretch ;
    flex-wrap: wrap;
    width:70% ;
    margin: 0px auto;
}

.mb-2{

    margin: 30px !important;
    border-radius: 30px;
}

.card-text{
    color: black;
    text-align: center;
    text-transform: math-auto;
    white-space: break-spaces;
    line-height: 20px;
}

.btn{
    margin: 10px;

}

.card-body{
    text-align: center;
}

.card-img, .card-img-top {
    border-radius: 30px !important;
    max-height: 200px;
    min-height: 200px;
}

</style>